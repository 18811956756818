import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '@/design-system/atoms/Text/Text';

import { Checkbox } from '../Checkbox';
import { TextField } from '../TextField';
import styles from './SearchableCheckbox.module.scss';

/**
 * SearchableCheckbox Component.
 *
 * This component uses 'Checkbox' and 'TextField' components.
 *
 * Example of using the component:
 *
 * ```
 * const SearchableCheckboxStory = (props) => {
 *  const [selectedOptions, setSelectedOptions] = React.useState([]);

 *  const handleCheckboxChange = (checked, value) => {
 *       if (checked) {
 *           setSelectedOptions([...selectedOptions, value]);
 *       } else {
 *           const newOptions = selectedOptions.filter((option) => option !== value);
 *           setSelectedOptions(newOptions);
 *       }
 *   };
 *
 *   return (
 *       <>
 *           {selectedOptions.length > 0 && (
 *               <div style={{ marginBottom: 32 }}>
 *                   <p>Selected Options:</p>
 *                   <p>
 *                       {selectedOptions.map((option, index) => (
 *                           <span key={index}>{option}, </span>
 *                       ))}
 *                   </p>
 *               </div>
 *           )}
 *
 *            <SearchableCheckbox {...props} onCheckboxChange={handleCheckboxChange} />
 *       </>
 *   );
 * };
 * ```
 */

export const SearchableCheckbox = (props) => {
    const {
        text,
        label,
        iconComponent,
        items = [],
        onCheckboxChange,
        onTextFieldKeyDown,
        isScrollable = false,
        fixedOption,
    } = props;
    const [keyword, setKeyword] = React.useState('');

    const fixedItem = fixedOption
        ? items.find(
              (item) => item.value.toLowerCase().trim() === fixedOption?.toLowerCase().trim(),
          )
        : null;

    const filteredOptions = (() => {
        if (!keyword) {
            return items;
        }

        return items.filter(
            (item) => item.value.toLowerCase().indexOf(keyword.toLowerCase().trim()) > -1,
        );
    })();

    const handleTextFieldKeyDown = React.useCallback(
        (e) => {
            onTextFieldKeyDown?.(e);
        },
        [onTextFieldKeyDown],
    );

    return (
        <div className={classnames(styles['searchable-checkbox'])}>
            <fieldset className={styles['searchable-checkbox__wrapper']}>
                {text && (
                    <legend>
                        <Text
                            className={classnames(styles['searchable-checkbox__text'])}
                            tag={Text.TAG.DIV}
                            variant={Text.VARIANT.T3}
                            content={text}
                        />
                    </legend>
                )}
                <div className={classnames(styles['searchable-checkbox__search'])}>
                    <TextField
                        label={label}
                        iconComponent={iconComponent}
                        value={keyword}
                        onChange={setKeyword}
                        onKeyDown={handleTextFieldKeyDown}
                    />
                </div>
                <div
                    className={classnames(
                        isScrollable &&
                            filteredOptions.length > 8 &&
                            styles['searchable-checkbox__scrollable'],
                    )}
                >
                    <ul className={classnames(styles['searchable-checkbox__list'])}>
                        {fixedItem && (
                            <li className={styles[`searchable-checkbox__list--fixed-item`]}>
                                <Checkbox
                                    onChange={(checked) =>
                                        onCheckboxChange(checked, fixedItem.value)
                                    }
                                    {...fixedItem}
                                />
                            </li>
                        )}
                        {filteredOptions.map((item) => {
                            const { value } = item;
                            if (fixedItem && value === fixedItem.value) {
                                return null;
                            }
                            return (
                                <li key={item.value}>
                                    <Checkbox
                                        onChange={(checked) =>
                                            onCheckboxChange(checked, item.value)
                                        }
                                        {...item}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </fieldset>
        </div>
    );
};

SearchableCheckbox.propTypes = {
    /**
     * text above the Input field
     */
    text: PropTypes.string,
    /**
     * Input Label
     */
    label: PropTypes.string,
    /**
     * Input Icon
     */
    iconComponent: PropTypes.elementType,
    /**
     * List of items (checkboxes)
     */
    items: PropTypes.arrayOf(PropTypes.shape(Checkbox.propTypes)),
    /**
     * This function is triggered every time that a checkbox is checked/unchecked
     */
    onCheckboxChange: PropTypes.func,
    /**
     * whether the checkbox list is scrollable
     */
    isScrollable: PropTypes.bool,
    /**
     * Fixed option at the top of the list
     */
    fixedOption: PropTypes.string,
};
