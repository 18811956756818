import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Radio as RACRadio } from 'react-aria-components';

import Typography from '@/design-system/atoms/Typography/Typography';

import styles from './Radio.module.scss';

export const Radio = ({ label, ...props }) => {
    return (
        <RACRadio className={classnames(styles['radio__label'])} {...props}>
            <span className={classnames(styles['radio__checkmark'])}></span>
            {label && (
                <Typography
                    variant={Typography.VARIANT.LABEL}
                    color={Typography.COLOR.INHERIT}
                    content={label}
                />
            )}
        </RACRadio>
    );
};
Radio.propTypes = {
    /**
     * The visual label of the radio button.
     */
    label: PropTypes.node,
    /**
     * The value of the radio button, used when submitting an HTML form.
     */
    value: PropTypes.string,
    /**
     * Whether the radio button is disabled or not.
     */
    isDisabled: PropTypes.bool,
};
