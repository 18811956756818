import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import {
    FieldError as RACFieldError,
    Label as RACLabel,
    RadioGroup as RACRadioGroup,
} from 'react-aria-components';

import { LazyIconAlertCircle } from '@/design-system/atoms/Icons/IconAlertCircle/Lazy';
import Typography from '@/design-system/atoms/Typography/Typography';

import styles from './RadioGroup.module.scss';
/**
 * RadioGroup component.
 *
 * This component uses `RadioGroup` and `Radio`(https://react-spectrum.adobe.com/react-aria/RadioGroup.html) from react-aria-component
 *
 * PROPS:
 * All `RadioGroup` props (https://react-spectrum.adobe.com/react-aria/RadioGroup.html#props)
 * `label` (visual label of the Radio group)
 *  `errorMessage` (custom error message for validation error)
 *
 */
export const RadioGroup = ({ label, errorMessage, className, labelClass, ...props }) => {
    return (
        <RACRadioGroup className={classnames(styles['radio-group'], className)} {...props}>
            {label && (
                <Typography
                    className={classnames(styles['radio-group__label'], labelClass)}
                    tag={Typography.TAG.DIV}
                    variant={Typography.VARIANT.T1}
                    color={Typography.COLOR.INHERIT}
                    content={<RACLabel>{label}</RACLabel>}
                />
            )}
            {props.children}
            <RACFieldError>
                <AnimatePresence>
                    {errorMessage && (
                        <motion.span
                            className={classnames(styles['radio-group__field-error'])}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            transition={{ duration: 0.1 }}
                            exit={{ height: 0, opacity: 0 }}
                        >
                            <LazyIconAlertCircle size="small" />
                            <Typography
                                variant={Typography.VARIANT.T3}
                                color={Typography.COLOR.INHERIT}
                                content={errorMessage}
                            />
                        </motion.span>
                    )}
                </AnimatePresence>
            </RACFieldError>
        </RACRadioGroup>
    );
};
RadioGroup.propTypes = {
    /**
     * Visual label of the radio group.
     */
    label: PropTypes.string,
    /**
     * The name of the input element, used when submitting an HTML form.
     */
    name: PropTypes.string,
    /**
     * The default value.
     */
    defaultValue: PropTypes.string,
    /**
     * Whether the input is disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * Whether user input is required on the input before form submission.
     */
    isRequired: PropTypes.bool,
    /**
     * Whether to use native HTML form validation to prevent form submission when the value is invalid.
     */
    validate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    /**
     * Custom error message displayed when the field is invalid.
     */
    errorMessage: PropTypes.string,
};
