import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox as AriaCheckbox } from 'react-aria-components';

import Typography from '../../Typography/Typography';
import styles from './Checkbox.module.scss';

/**
 * Checkbox component.
 *
 * This component uses `Checkbox` (https://react-spectrum.adobe.com/react-aria/Checkbox.html) from react-aria-component
 *
 * PROPS:
 * All `Checkbox` props (https://react-spectrum.adobe.com/react-aria/Checkbox.html#props)
 * `label` (visual label of the input element).
 * If the checkbox does not have a visible label, an aria-label or aria-labelledby prop must be passed to identify the element to assistive technology.
 *
 */

export const Checkbox = ({ label, ...props }) => {
    return (
        <AriaCheckbox className={classnames(styles['checkbox__label'])} {...props}>
            <span className={classnames(styles['checkbox__checkmark'])}></span>
            {label && (
                <Typography
                    variant={Typography.VARIANT.LABEL}
                    color={Typography.COLOR.INHERIT}
                    content={label}
                />
            )}
        </AriaCheckbox>
    );
};
Checkbox.propTypes = {
    /**
     * Visual label of the input element.
     */
    label: PropTypes.node,
    /**
     * Whether the element should be selected (uncontrolled).
     */
    defaultSelected: PropTypes.bool,
    /**
     * Whether the input is disabled.
     */
    isDisabled: PropTypes.bool,
    /**
     * The value of the input element, used when submitting an HTML form.
     */
    value: PropTypes.string,
    /**
     * The name of the input element, used when submitting an HTML form.
     */
    name: PropTypes.string,
};
