import classnames from 'classnames';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { IconSearch } from '@/design-system/atoms/Icons/IconSearch';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import styles from './SearchBar.module.scss';

const findChildByDisplayName = (children, displayName, props = {}) => {
    const childArray = React.Children.map(children, (child) =>
        child.type.displayName === displayName ? React.cloneElement(child, props) : null,
    );

    if (childArray?.length > 1) {
        throw new Error(`Cannot have multiple ${displayName} inside a Modal component.`);
    }

    return childArray[0];
};

/**
 *
 * The Search Bar Molecule is a form component that can contain inputs, a search button and a mobile footer.
 */

const SearchBar = ({ children, submitHandler, oneInput, ...props }) => {
    const Inputs = findChildByDisplayName(children, 'Inputs');
    const SubmitButton = findChildByDisplayName(children, 'SubmitButton');
    const MobileFooter = findChildByDisplayName(children, 'MobileFooter');

    return (
        <div
            className={classnames(
                styles[`search-bar__form`],
                oneInput && styles[`search-bar__form--one-input`],
            )}
        >
            <form onSubmit={submitHandler} {...props}>
                <div className={styles[`search-bar__input-wrapper`]}>{Inputs}</div>
                <div className={styles[`search-bar__button-wrapper`]}>{SubmitButton}</div>
            </form>
            {MobileFooter}
        </div>
    );
};

const Inputs = ({ children }) => {
    const newChildren = React.Children.toArray(children)
        .flatMap((child, index) => [
            <React.Fragment key={index}>{child}</React.Fragment>,
            <div key={`divider-${index}`} className={styles[`search-bar__input_divider`]} />,
        ])
        .slice(0, -1);

    return <>{newChildren}</>;
};
Inputs.displayName = 'Inputs';
SearchBar.Inputs = Inputs;

const SubmitButton = ({ label, ariaLabel, iconComponent, buttonStyle, onSubmit, ...props }) => {
    const breakpoints = useBreakpoint();
    return (
        <Button
            label={label ? label : breakpoints?.large ? null : 'Search'}
            ariaLabel={ariaLabel ? ariaLabel : 'Submit Location Search'}
            buttonStyle={buttonStyle ? buttonStyle : Button.STYLE.PRIMARY}
            iconComponent={iconComponent ? iconComponent : breakpoints?.large ? IconSearch : null}
            className={styles[`search-bar__button`]}
            onClick={onSubmit}
            {...props}
        />
    );
};
SubmitButton.displayName = 'SubmitButton';
SearchBar.SubmitButton = SubmitButton;

const MobileFooter = ({ children, className }) => {
    const breakpoints = useBreakpoint();
    return (
        <>
            {!breakpoints?.large && (
                <div className={classnames(styles[`search-bar__mobile-buttons`], className)}>
                    {children}
                </div>
            )}
        </>
    );
};
MobileFooter.displayName = 'MobileFooter';
SearchBar.MobileFooter = MobileFooter;

export default SearchBar;
