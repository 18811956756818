import React from 'react';

import { CardContent } from '@/design-system/atoms/Cards/CardContent';
import { CardImage as CardImageDS } from '@/design-system/atoms/Cards/CardImage';
import { CardVideo as CardVideoDS } from '@/design-system/atoms/Cards/CardVideo';
import * as ImageStories from '@/design-system/atoms/Image/Image.stories';
import { Theme } from '@/design-system/atoms/Theme';

import ContentCarousel from './ContentCarousel';

const cardDescription = [
    ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.'].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
    ].join(' '),
    ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do'].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
        'Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'eiusmod tempor incididunt ut labore et dolore aliqua.',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'Nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
    [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
        'Eiusmod reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    ].join(' '),
];

const CarouselItemCardContent = ({ index }) => (
    <ContentCarousel.Card fullHeight>
        <CardContent
            title={`Card Title ${index}`}
            body={cardDescription[index]}
            cta={{
                href: 'https://www.atlantichealth.org',
            }}
        />
    </ContentCarousel.Card>
);

export default {
    title: 'Design System/Molecules/Content Carousel',
    component: ContentCarousel,
    tags: ['autodocs'],
    argTypes: {
        theme: {
            options: Object.values(Theme.NAME),
            control: { type: 'radio' },
        },
    },
    args: {
        theme: Theme.NAME.CONDITIONS,
        title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        body: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do',
            'eiusmod tempor incididunt ut labore et dolore aliqua.',
        ].join(' '),
        items: [...Array(10)].map((_, i) => <CarouselItemCardContent key={i} index={i} />),
        cta: {
            label: 'Lorem ipsum dolor',
        },
    },
};

export const Default = {
    render(args) {
        return <ContentCarousel {...args} />;
    },
};

export const FewItems = {
    render(args) {
        return <ContentCarousel {...args} />;
    },
    args: {
        items: [...Array(3)].map((_, i) => <CarouselItemCardContent key={i} index={i} />),
    },
};

const CarouselItemCardImage = ({ index }) => (
    <ContentCarousel.Card fullHeight>
        <CardImageDS
            image={ImageStories.Default.args}
            title={`Card Title ${index}`}
            body={cardDescription[index]}
            cta={{
                href: 'https://www.atlantichealth.org',
            }}
        />
    </ContentCarousel.Card>
);

export const CardImage = {
    render(args) {
        return <ContentCarousel {...args} />;
    },
    args: {
        items: [...Array(10)].map((_, i) => <CarouselItemCardImage key={i} index={i} />),
    },
};

const CarouselItemCardVideo = ({ index }) => (
    <ContentCarousel.Card fullHeight>
        <CardVideoDS
            videoType="youTube"
            videoId="3e4k76I7ubs"
            duration={278}
            title="Video Title"
            body={cardDescription[index]}
            cta={{
                href: 'https://www.atlantichealth.org',
            }}
        />
    </ContentCarousel.Card>
);

export const CardVideo = {
    render(args) {
        return <ContentCarousel {...args} />;
    },
    args: {
        items: [...Array(10)].map((_, i) => <CarouselItemCardVideo key={i} index={i} />),
    },
};

export const AnyContent = {
    render(args) {
        return <ContentCarousel {...args} />;
    },
    args: {
        items: [...Array(10)].map((_, i) => (
            <div key={i} style={{ height: 100, width: 100, backgroundColor: 'lightblue' }} />
        )),
    },
};
