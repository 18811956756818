import { googlePlacesAutocomplete } from '@/libs/googleMaps/places';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';

export const locationCheck = async ({ zipCityValue, suggestions }) => {
    if (
        !zipCityValue ||
        !isNaN(zipCityValue) ||
        (zipCityValue && isNaN(zipCityValue) && zipCityValue.length < 3)
    )
        return {};

    let isGeocoded = false;
    let newLocation = null;

    if (arrayNotEmpty(suggestions) && suggestions[0]?.text === zipCityValue) {
        isGeocoded = true;
    } else {
        const placeResponse = await googlePlacesAutocomplete({ input: zipCityValue });
        if (arrayNotEmpty(placeResponse) && placeResponse[0]?.description) {
            newLocation = placeResponse[0]?.description;
            isGeocoded = true;
        }
    }

    return { isGeocoded, newLocation };
};
