import React from 'react';

import Image from './Image';

export default {
    title: 'Design System/Atoms/Image',
    component: Image,
    tags: ['autodocs'],
    argTypes: {
        alt: {
            table: {
                category: 'Content',
            },
        },
        defaultAspectRatio: {
            options: Object.values(Image.ASPECT_RATIO),
            table: {
                category: 'Content',
            },
        },
        defaultImgSrc: {
            table: {
                category: 'Content',
            },
        },
        defaultImgSrcSet: {
            table: {
                category: 'Content',
            },
        },
        addtClassName: {
            table: {
                category: 'Other',
            },
        },
    },
};

// More on component templates: https://storybook.js.org/docs/react/writing-stories/introduction#using-args
const Template = (args) => (
    <div style={args.style}>
        <Image {...args} />
    </div>
);

export const Default = Template.bind({});
Default.args = {
    id: 'image-0bd4166204',
    defaultImgSrc: 'https://picsum.photos/id/322/1200/787',
    defaultImgSrcSet:
        'https://picsum.photos/id/322/400/320 400w,https://picsum.photos/id/322/800/428 800w,https://picsum.photos/id/322/1200/787 1200w,https://picsum.photos/id/322/1600/960 1600w',
    decorative: false,
    srcUriTemplate:
        '/is/image/atlantichealthdev/Heart%20Assesment?qlt=82&wid=%7B.width%7D&ts=1715362820350&dpr=off',
    lazyEnabled: true,
    lazyThreshold: 0,
    areas: [],
    uuid: '9abfde59-536a-42d0-b18c-dd8f0062125c',
    sizes: '(max-width: 767px) 800px, (min-width: 768px) 1200px, (min-width: 1440px) 1600px, 100vw',
    height: '411',
    width: '545',
    widths: [400, 800, 1200, 1600],
    srcset: 'https://picsum.photos/id/322/400/320 400w,https://picsum.photos/id/322/800/428 800w,https://picsum.photos/id/322/1200/787 1200w,https://picsum.photos/id/322/1600/960 1600w',
    dmImage: true,
    src: 'https://picsum.photos/id/322/1200/787',
    ':type': 'ahs/components/image',
    alt: 'Image alt text',
    loading: 'lazy',
    roundedCorners: false,
};

Default.parameters = {
    jest: ['Image.test.js'],
};

export const SquareAspectRatio = Template.bind({});
SquareAspectRatio.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['1:1'],
    sizes: undefined,
    style: {
        maxWidth: '100px',
    },
};

export const TwoByThree = Template.bind({});
TwoByThree.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['2:3'],
    style: {
        maxWidth: '200px',
    },
};

export const ThreeByTwo = Template.bind({});
ThreeByTwo.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['3:2'],
    style: {
        maxWidth: '300px',
    },
};

export const FourByThree = Template.bind({});
FourByThree.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['4:3'],
    style: {
        maxWidth: '400px',
    },
};

export const NineBySixteen = Template.bind({});
NineBySixteen.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['9:16'],
    style: {
        maxWidth: '900px',
    },
};

export const SixteenByNine = Template.bind({});
SixteenByNine.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['16:9'],
    style: {
        maxWidth: '1600px',
    },
};

export const WithFallbackSrc = Template.bind({});
WithFallbackSrc.args = {
    defaultImgSrc: '/content/404.png',
    // get a placeholder image from: https://hero-placeholder.vercel.app/
    fallbackSrc:
        'https://images.unsplash.com/photo-1596854407944-bf87f6fdd49e?ixid=MnwyOTgxMTZ8MHwxfHNlYXJjaHwxfHxjYXR8ZW58MHwyfHx8MTY1NzgyMDY5NQ&ixlib=rb-1.2.1',
};

export const WithRoundedCourners = Template.bind({});
WithRoundedCourners.args = {
    ...Default.args,
    defaultAspectRatio: Image.ASPECT_RATIO['1:1'],
    mobileAspectRatio: Image.ASPECT_RATIO['1:1'],
    roundedCorners: true,
    style: {
        maxWidth: '400px',
    },
};

export const ClickableImage = Template.bind({});
ClickableImage.args = {
    ...Default.args,
    style: {
        maxWidth: '400px',
    },
    destinationUrl: '#',
    ariaLabel: 'image link aria label',
};
