import classNames from 'classnames';
import React from 'react';

import Typography from '../Typography';
import styles from './Loading.module.scss';

const Dot = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
    >
        <circle cx="4.5" cy="4" r="4" fill="currentColor" />
    </svg>
);

export const Loading = (props) => {
    const { className, description = 'Loading...', variant = Typography.VARIANT.H6 } = props;

    return (
        <div className={classNames(styles['loading'], className)}>
            <div className={styles['loading__dots']}>
                <Dot
                    className={classNames(styles['loading__dot'], styles['loading__dot--first'])}
                />
                <Dot
                    className={classNames(styles['loading__dot'], styles['loading__dot--second'])}
                />
                <Dot
                    className={classNames(styles['loading__dot'], styles['loading__dot--third'])}
                />
            </div>
            {description && (
                <div className={styles['loading__description']}>
                    <Typography variant={variant} content={description} />
                </div>
            )}
        </div>
    );
};
